import activity from './m-field-trip-reflection.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Field Trip Reflection',
  icon: 'mdi-bus',
  description: 'Pilotcity Field Trip',
  status: true,
  styling: { borderColor: '#C3177E' },
  setupRequired: false,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-field-trip-reflection',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {},
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
